import { NextPage } from "next"
import Marquee from "react-fast-marquee"
import BlankPageWrapper from "../../../layouts/blank-page-wrapper/views/BlankPageWrapper"
import staticData, {
	artistInterestFormURL,
	artistProfiles,
	grizzliesEnewsFormUrl,
	pastArtists,
	seasonBaseURL,
	staticAssetsBaseURL
} from "../models/staticData"

import "swiper/css"
import "swiper/css/navigation"
import styles from "../assets/css/index.module.scss"
import { DateTime } from "luxon"

const BoilerplateView: NextPage = () => (
	<BlankPageWrapper {...staticData}>
		<main className={`${styles.mainContainer}`}>
			<section className={styles.titleContainer}>
				<img src={`${seasonBaseURL}img/Grid_OffWhiteBackground_1920x1080.jpg`} alt="" />
				<img alt="" src={`${seasonBaseURL}img/LeftStripTeal.png`} className="img-fluid" />
				<img alt="" src={`${seasonBaseURL}img/RightStrip.png`} className="img-fluid" />
				<img alt="" src={`${seasonBaseURL}img/TopStrip.png`} />
				<div className={styles.titleContent}>
					<div className={styles.logoContainer}>
						<img
							alt="For Grizz Fans"
							src={staticAssetsBaseURL + "img/ForGrizzFans.png"}
							className={styles.ForGrizzFans}
						/>
						<img
							alt="By Grizz Fans"
							src={staticAssetsBaseURL + "img/ByGrizzFans.png"}
							className={styles.ByGrizzFans}
						/>
						<img
							alt="191 Collabs Logo"
							src={staticAssetsBaseURL + "img/MG_191Collabs_Hennessy_Presented_Stacked_Black.svg"}
							className={styles.logo}
						/>
					</div>

					<h1 className="d-flex flex-column align-items-center text-center">
						<span>Collab</span> <span className="text-nowrap">with us</span>
					</h1>

					<p className="max-sm">
						If you’re a creative from Memphis and want to collaborate with us, we want to hear from
						you.
					</p>

					<a className={styles.buttonText} href={artistInterestFormURL}>
						apply now {">"}
					</a>
				</div>
			</section>

			<Marquee autoFill className={styles.marquee} pauseOnHover speed={50}>
				{pastArtists.map((t, i) => (
					<p key={i} className="artistMarquee">
						<span>{t.name}</span> <span>*</span>
						{i % 5 === 5 - 1 ? (
							<>
								<a href="#">
									<strong>
										<em>this could be YOU</em>
									</strong>
								</a>{" "}
								<span>*</span>
							</>
						) : null}
					</p>
				))}
				{/* <p className="artistMarquee">
					<a href="#">
						<strong>
							<em>this could be YOU</em>
						</strong>
					</a>{" "}
					<span>*</span>
				</p> */}
			</Marquee>

			<section className={styles.designersContainer}>
				<div className={`${styles.imageDecor} ${styles.left}`}>
					<img alt="" src={staticAssetsBaseURL + "img/TealSquare_1000.jpg"} />
				</div>
				<div className={`${styles.imageDecor} ${styles.left} ${styles.bottom}`}>
					<img alt="" src={staticAssetsBaseURL + "img/TealSquare_1000.jpg"} />
				</div>
				<div className={`${styles.imageDecor} ${styles.right}`}>
					<img alt="" src={staticAssetsBaseURL + "img/BlueHorizontal_1200.jpg"} />
				</div>
				<div className="mx-auto max-lg">
					<h2>
						<span>DESIGNED</span> <span className="text-nowrap">BY MEMPHIS</span>{" "}
						<span className="text-nowrap">FOR MEMPHIS FANS</span>
					</h2>

					<div className={styles.designersDescription}>
						<p className="mb-0">
							191 Collabs, presented by Hennessy, celebrates our city’s unique voice and vibe
							through one-of-a-kind Grizz gear. Showcasing designer streetwear to graphic tees all
							designed by local Memphis creatives. At select Grizzlies games this season, fans at
							FedExForum will be able to shop these limited drops at the Grizzlies Den.
						</p>
					</div>

					<div id="designers" className={styles.designers}>
						{artistProfiles.map((profile, index) => (
							<div key={index} className={styles.designerItem}>
								<div className={styles.designerItemHeader}>
									{profile.logo && profile.logo !== "" ? (
										<img src={seasonBaseURL + profile.logo} alt={profile.name} />
									) : (
										<h3>{profile.name}</h3>
									)}
								</div>
								<div className={styles.separator}></div>
								<div className={styles.designerItemMeta}>
									<a className={`${styles.pillButton}`} href={profile.profile_link}>
										<span>
											{DateTime.fromFormat(profile.datetime, "yyyy/MM/dd HH:mm")
												.toFormat("LLL d")
												.toString()}
										</span>{" "}
										{profile.vs_at} <span>{profile.opponent.name}</span> {">"}
									</a>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>

			<div className={styles.interestContainer}>
				<div className="mx-auto max-xl">
					<div className={styles.interestBlock}>
						<div className={styles.interestBlockMeta}>
							<h4>Collab with us</h4>
							<p>
								If you’re a creative from Memphis and want to collaborate with us, we want to hear
								from you.
							</p>
						</div>
						<div className={styles.separator}></div>
						<div className={styles.interestBlockCTA}>
							<a
								className={styles.pillButton}
								target="_blank"
								rel="noreferrer"
								href={artistInterestFormURL}
							>
								apply now {">"}
							</a>
						</div>
					</div>
					<div className={styles.interestBlock}>
						<div className={styles.interestBlockMeta}>
							<h4>Stay Tuned</h4>
							<p>
								Get the latest news on 191 Collabs, Grizzlies ticket offers, merch deals, and
								exclusive events.
							</p>
						</div>
						<div className={styles.separator}></div>
						<div className={styles.interestBlockCTA}>
							<a
								className={styles.pillButton}
								target="_blank"
								rel="noreferrer"
								href={grizzliesEnewsFormUrl}
							>
								Sign Up {">"}
							</a>
						</div>
					</div>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.footerLinks}>
					<a href="https://nba.com/grizzlies">grizzlies.com</a>
					<span />
					<a href="https://191collabs.com">191Collabs.com</a>
				</div>
			</footer>
		</main>
	</BlankPageWrapper>
)

export default BoilerplateView
